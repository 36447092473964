import React from "react";
import * as CSS from "./footer.module.css"
const Footer = () => {
  return(
    <footer className={CSS.Footer}>
      <span>&copy; Copyright {new Date().getFullYear()} heatngrow.com.au</span>
    </footer>
  )
}

export default Footer
