import * as React from 'react'
import {Link} from "gatsby"
import * as CSS from "./header.module.css"
import NavToggle from "../nav-toggle/nav-toggle.component"
import SiteLogo from "../site-logo/site-logo.component"

const NavListItem = ({to, children}) => {
  return (
    <li className={CSS.NavListItem}>
      <Link
        className={CSS.Link}
        activeClassName={`${CSS.active}`}
        to={to}
      >{children}</Link>
    </li>
  )
}

// todo: nav mq based on content
const Header = () => {

  return (
    <header
      className={CSS.Header}
    >
      <Link
        className={CSS.LogoWrapper}
        to="/"
      >
        <SiteLogo/>
      </Link>
      <NavToggle/>
      <nav
        className={CSS.Nav}
      >
        <ul className={CSS.NavList}>
          <NavListItem to="/">Home</NavListItem>
          <NavListItem to="/stockists/">Find a Stockist</NavListItem>
          <NavListItem to="/resources/">Resources</NavListItem>
          <NavListItem to="/contact/">Contact Us</NavListItem>
        </ul>
      </nav>
    </header>
  )
}
export default Header
