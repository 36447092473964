import React,{useContext} from "react"
import { MdMenu, MdClose } from "react-icons/md"
import {MenuContext} from "../../context/menu-context.component"
import * as CSS from "./nav-toggle.module.css"

const NavToggle = () => {

  const {isOpen, toggleIsOpen} = useContext(MenuContext);


  return (
    <button
      className={CSS.NavToggle}
      onClick={toggleIsOpen}
      aria-label={isOpen ? 'close menu' : 'open menu'}
    >
      { isOpen ? <MdClose/> : <MdMenu/>}
    </button>
  )
}

export default NavToggle
