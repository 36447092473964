import React, { useState } from "react"


interface Props {
  children: React.ReactNode
}


export const MenuContext = React.createContext({
  isOpen: false,
  toggleIsOpen: () => {
  },
})

const MenuContextProvider = ({ children }: Props) => {

  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState)
  }

  return (
    <MenuContext.Provider value={{
      isOpen,
      toggleIsOpen,
    }}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuContextProvider
