import * as React from "react"
import {Logo} from "./site-logo.module.css"

const SiteLogo = () => {
  return (
    <svg
      className={Logo}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 253.5 30"
    >
      <title>Heat 'n' Grow Logo</title>
      <g>
        <g>
          <path
            d="M48 8.5V.2h5V21h-5v-8.4H37.5V21h-5.1V.2h5.1v8.3z"
          />
          <path
            d="M55.5 21V.1h15V4h-9.8v4.3h9.9v3.9h-10v5h10l-.1 3.7z"
          />
          <path
            d="M88.2 21h5.6L84.6.2h-6S70 20.7 70.2 21h5.6l1.4-4h9.3zm-9.5-7.8l3-8 3.3 8z"
          />
          <path d="M101.2 4v17H96V4h-7.5V.4h20.2V4h-7.5z"/>
        </g>

        <g>
          <path d="M125.4 7.3h2.5a47716.2 47716.2 0 0 1-3 4.2l.5-4.2z"/>
          <path
            d="M107.6 16.5l.1-.3 3.9-8.6c0-.3.2-.3.4-.3h4c.2 0 .3 0 .4.2l1.7 3.8.2.4.2-.4 1.6-3.7c.1-.3.2-.3.4-.3h4.1v.3l-4 8.6c0 .3-.2.3-.4.3h-4c-.2 0-.3 0-.3-.2l-1.8-3.8-.1-.4-.2.4-1.7 3.8c0 .1-.1.2-.3.2h-4-.2z"
          />
          <path
            d="M107.6 11.5l.3-3 .2-1c0-.1 0-.2.2-.2h2.4a85439 85439 0 0 1-3 4.2z"/>
        </g>
        <g>
          <path
            d="M145.5 13.9h-3V10h8.2V18.3c0 .2 0 .3-.2.4-1 .9-2 1.4-3.3 1.8a16 16 0 0 1-10.2 0c-2.6-.8-4.7-2.4-6.2-4.8a9.7 9.7 0 0 1-1.2-5.3c0-2.4.8-4.6 2.4-6.4 1.7-1.8 3.7-3 6.1-3.5A18.2 18.2 0 0 1 150 1.6c.2 0 .3.2.3.4v4.2l-1.5-.6c-2.1-.8-4.4-1.3-6.8-1.2-1.6 0-3.2.3-4.6 1.4a6.2 6.2 0 0 0-2.5 5.6c.2 3 2.5 4.9 5.1 5.4 1.8.4 3.5.3 5.2-.4.2 0 .3-.2.3-.4v-2.1z"
          />
          <path
            d="M158 12.6v8.5h-5.2V.3h9.3c1.6 0 3.1.4 4.5 1.4a5.7 5.7 0 0 1 1.6 7 5.9 5.9 0 0 1-2.6 2.5l-.2.1.7.6c.8.7 1.4 1.7 1.9 2.8l2.9 6.2v.2h-5.3c-.3 0-.4-.1-.5-.4L163 16l-1.3-2.1a2.9 2.9 0 0 0-2.5-1.3H158zm0-3.8h2.9a4 4 0 0 0 1.3-.3c1-.4 1.3-1.2 1.3-2.2 0-1-.5-1.7-1.4-2l-.8-.2H158v4.7z"
          />
          <path
            d="M181.3 0c2.7 0 5 .5 7.1 2 2.5 1.6 4.2 3.8 4.7 6.7.7 3.5-.2 6.5-2.6 9-2.2 2.4-5 3.5-8.2 3.6-2.8.2-5.5-.3-7.9-2a10.3 10.3 0 0 1-2-15.6c2-2.3 4.7-3.4 7.8-3.6l1-.1zm6.7 10.7V10c-.2-2.3-1.2-4-3.3-5a7.3 7.3 0 0 0-4.3-.7 6 6 0 0 0-4.8 3.2c-.6 1-.7 2.2-.7 3.4a6 6 0 0 0 2.4 4.8c1 .7 2.2 1 3.4 1.2 1.6.1 3 0 4.4-.9 2-1.2 2.9-3.1 2.9-5.4z"
          />
          <path
            d="M226 .2l-9 21h-2.2c-.2 0-6.4-13.5-6.4-13.5l-6.5 13.5h-2.2c-3-7.3-6.5-14.4-9.1-21h5.4c1.9 3.8 3.3 8 5 11.4L206.3.2h4.1l5.4 11.4L220.5.2z"
          />

          <path
            d="M225 9.8h-.2v3.5l2.7.4a41.3 41.3 0 0 1 2.6.5l8.3 4.8 7.8 4.4 3 1.7.4.2c-.5.4-1 .4-1.5.5l-4.5.6-233.8-.1-4.4-.6c-.5 0-1-.1-1.5-.5l.3-.2 3-1.7L15 19l8.3-4.7.5-.2 2-.3 2.6-.4V10h-.1l-5.3.8c-.5 0-1 .2-1.5.5L1.6 22.5a3 3 0 0 0-1.4 1.7c-.3.7-.3 1.4 0 2.1l.3.6c.8 1 2 1.7 3.2 2 2 .4 4.2.8 6.3 1h233.4c2.2 0 4.3-.4 6.4-.9 1.3-.3 2.4-1 3.2-2 .2-.1.2-.4.3-.6.3-.7.3-1.4 0-2.1-.2-.8-.7-1.4-1.4-1.8l-20-11.3c-.5-.3-1-.5-1.6-.6l-5.3-.8z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SiteLogo
