import React, { useContext } from "react"
import { Link } from "gatsby"
import { animated, useSpring } from "react-spring"
import * as CSS from "./slide-down-menu.module.css"
import { MenuContext } from "../../context/menu-context.component"


const SlideDownMenu = () => {
  const { isOpen, toggleIsOpen} = useContext(MenuContext)


  const props = useSpring({
    transform: isOpen ? 'translateY(5rem)' : 'translateY(-22rem)'
  })

  return (
    <animated.nav className={CSS.Nav}
         style={props}
    >
      <ul className={CSS.NavList}>
        <li className={CSS.NavListItem}>
          <Link
            onClick={toggleIsOpen}
            className={CSS.Link}
            tabIndex={isOpen ? '0' : '-1'}
            to="/"
          >Home</Link>
        </li>
        <li className={CSS.NavListItem}>
          <Link
            onClick={toggleIsOpen}
            className={CSS.Link}
            tabIndex={isOpen ? '0' : '-1'}
            to="/stockists/"
          >Stockists</Link>
        </li>
        <li className={CSS.NavListItem}>
          <Link
            onClick={toggleIsOpen}
            className={CSS.Link}
            tabIndex={isOpen ? '0' : '-1'}
            to="/resources/"
          >Resources</Link>
        </li>
        <li className={CSS.NavListItem}>
          <Link
            onClick={toggleIsOpen}
            className={CSS.Link}
            tabIndex={isOpen ? '0' : '-1'}
            to="/contact/"
          >Contact</Link>
        </li>
      </ul>
    </animated.nav>
  )
}

export default React.memo(SlideDownMenu)
