import * as React from "react";
import SlideDownMenu from "./slide-down-menu/slide-down-menu.component";
import "../styles/normalize.css";
import "../styles/global.css";
import { pageContainer } from "./layout.module.css";
import Header from "./header/header.component";
import Footer from "./footer/footer.component";
import MenuContextProvider from "../context/menu-context.component";


const Layout = ({ children }) => {
  return (
    <MenuContextProvider>
      <div className={pageContainer}>
        <Header/>
        <SlideDownMenu/>
        {children}
        <Footer/>
      </div>
    </MenuContextProvider>
  );
};

export default Layout;
